<template>
    <button
        type="button"
        class="btn btn-primary d-flex justify-content-center align-items-center w-100"
        @click="$emit('click', $event)"
    >
        <span v-show="busy" class="spinner-border spinner-border-sm me-1"></span>
        <span>{{ text }}</span>
    </button>
</template>
<script>
export default {
    props: {
        busy: Boolean,
        text: String
    },
    emits: ["click"],
}
</script>