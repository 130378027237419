<template>
    <div>
        <h2 class="h3">What does this tool do?</h2>
        <p>This tool accepts a list of Partial URLs as input, attempts to resolve each of these to a Fully Qualified URL, and downloads a CSV file containing one row for each resolution attempt. The CSV file has the following columns:</p>
        <ul>
            <li>
                <strong>PartialUrl</strong> - The Partial URL provided as input.
            </li>
            <li>
                <strong>FullyQualifiedUrl</strong> - The Fully Qualified URL if resolution succeeded. Otherwise, this will be empty.
            </li>
            <li>
                <strong>Error</strong> - Detailed error information if resolution failed. Otherwise, this will be empty.
            </li>
        </ul>
        <h2 class="h3">What is a Fully Qualified URL?</h2>
        <p>A fully qualified URL specified the full address including the protocol, hostname, and file name of a web resource. An example of a fully qualified URL is http://www.example.com/index.html, which indicates a protocol (http), a hostname (www.example.com) and a file name (/index.html).</p>
        <h2 class="h3">What is a Partial URL?</h2>
        <p>A partial URL does not specify the full address. An example of a partial url is example.com, which is missing the protocol, a portion of the hostname, and the file name.</p>
        <h2 class="h3">How does this tool resolve Partial URLs into Fully Qualified URLs?</h2>
        <p>It makes a few educated URL guesses and then it checks each guess for validity by making a HTTP request. If the server returns a 302 or 301 response, the redirect is followed. If the server returns a 2XX response, the guess is considered valid. Otherwise, the guess is considered invalid. The first valid guess is chosen as the Fully Qualified URL.</p>
        <h2 class="h3">What does the Max Degree of Parallelism setting do?</h2>
        <p>It controls the maximum number of Partial URLs that will be processed in parallel. Increasing this setting improves performance, but might cause this tool to be recongized as a bot.</p>
        <h2 class="h3">What does the HTTP Request Timeout setting do?</h2>
        <p>It controls the maximum amount of time this tool will wait for a server response. Decreasing this setting improves performance, but might decrease accuracy.</p>
        <h2 class="h3">What does the User Agent setting do?</h2>
        <p>It sets the User-Agent header used by this tool. Some servers might present different content depending on User-Agent. This setting defaults to your current browser's User Agent.</p>
    </div>
</template>