<template>
  <div class="container">
    <Header />
    <div class="row mb-5">
      <div class="col-lg">
        <FormPanel
          :disabled="disabled"
          :busy="disabled"
          :submitButtonText="submitButtonText"
          :formData="formData"
          @update:formData="formData = $event"
          @submit="handleSubmit"
        />
      </div>
      <div class="col-lg mt-3 mt-lg-0">
        <DocumentationPanel />
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import FormPanel from "@/components/FormPanel.vue";
import DocumentationPanel from "@/components/DocumentationPanel.vue";
import { ref } from "vue";

const submitButtonTextFn = (percentComplete) => {
  if (percentComplete === undefined) {
    return "Resolve Partial URLs";
  }
  percentComplete = Math.round(percentComplete * 100);
  percentComplete = percentComplete < 0 ? 0 : percentComplete;
  percentComplete = 100 < percentComplete ? 100 : percentComplete;
  return `Processing (${percentComplete}% Complete)`
}

const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

export default {
  components: {
    Header,
    FormPanel,
    DocumentationPanel,
  },
  setup() {
    const disabled = ref(false);
    const submitButtonText = ref(submitButtonTextFn());

    const formData = ref({
      partialUrls: [],
      maxDegreeOfParallelism: 25,
      timeoutSeconds: 10,
      userAgent: window.navigator.userAgent
    });

    const triggerFileDownload = (fileName, fileBlob) => {
      const a = document.createElement("a");
      a.href = URL.createObjectURL(fileBlob);
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        URL.revokeObjectURL(a.href);
        document.body.removeChild(a);
      }, 0);
    }

    const handleSubmit = async () => {
      disabled.value = true;
      submitButtonText.value = submitButtonTextFn(0);

      var jobId = null;

      do {

        // Start Job
        try {
          const response = await fetch(`${process.env.VUE_APP_API_PREFIX}/PartialUrlResolver/Start`, {
            method: "POST",
            headers: { "Content-Type": "application/json; charset=utf-8" },
            body: JSON.stringify(formData.value)
          });
          if (!response.ok) {
            throw new Error(`Non 2XX status code: ${response.status}`)
          }
          const jobInfo = await response.json();
          jobId = jobInfo.jobId
        } catch (e) {
          console.error(e);
          alert("A network error occurred.");
          break;
        }

        // Start updating Percent Complete
        let errors = 0;
        // eslint-disable-next-line no-constant-condition
        while (true) {
          try {
            const response = await fetch(`${process.env.VUE_APP_API_PREFIX}/PartialUrlResolver/Progress/${jobId}`);
            if (!response.ok) {
              throw new Error(`Non 2XX status code: ${response.status}`)
            }
            const status = await response.json();
            submitButtonText.value = submitButtonTextFn(status.percentComplete);
            errors = 0;
            if (status.percentComplete === 1) {
              break;
            }
            await sleep(1000);
          } catch (e) {
            console.error(e);
            // Abort polling if we get ten consecutive errors
            if (++errors >= 10) {
              break;
            }
          }
        }

        // Download file. This may take a long time
        try {
          const response = await fetch(`${process.env.VUE_APP_API_PREFIX}/PartialUrlResolver/Download/${jobId}`);
          if (!response.ok) {
            throw new Error(`Non 2XX status code: ${response.status}`)
          }
          const contentDisposition = response.headers.get("Content-Disposition");
          const fileName = /filename="(.*)";/.exec(contentDisposition)[1];
          const fileBlob = await response.blob();
          triggerFileDownload(fileName, fileBlob)
        } catch (e) {
          console.error(e);
          alert("A network error occurred.");
          break;
        }

        // eslint-disable-next-line no-constant-condition
      } while (false);

      submitButtonText.value = submitButtonTextFn();
      disabled.value = false;
    }
    return { disabled, submitButtonText, formData, handleSubmit }
  }
}
</script>
