/* eslint-disable vue/multi-word-component-names */
<template>
    <div>
        <a href="https://crowdsourcelawyers.com/" target="_blank" rel="noopener">
            <img
                src="@/assets/crowdsource-lawyers-logo-1x.webp"
                srcset="@/assets/crowdsource-lawyers-logo-1x.webp, @/assets/crowdsource-lawyers-logo-2x.webp 2x"
                alt="CrowdSource Lawyers Logo"
                width="196"
                height="90"
            />
        </a>
    </div>
</template>