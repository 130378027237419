<template>
    <div class="d-flex">
        <input
            type="range"
            class="form-range"
            :id="id"
            :min="min"
            :max="max"
            :value="value"
            @change="$emit('update:value', parseInt($event.target.value))"
        />
        <div class="ps-2">{{ value }}</div>
    </div>
</template>
<script>
export default {
    props: {
        id: String,
        min: Number,
        max: Number,
        value: Number,
    },
    emits: ["update:value"],
    setup() {
        return {};
    },
}
</script>