<template>
    <div class="panel">
        <h1 class="lh-1 mb-3">Partial URL Resolver</h1>
        <form>
            <fieldset :disabled="disabled">
                <div class="mb-3">
                    <label
                        for="2D825400-6F7C-46D2-A5D6-EB03B0BCE59F"
                        class="form-label"
                    >Partial URL List:</label>
                    <textarea
                        class="form-control"
                        id="2D825400-6F7C-46D2-A5D6-EB03B0BCE59F"
                        rows="10"
                        placeholder="PartialUrl1.com&#10;PartialUrl2.com&#10;&#8230;&#10;PartialUrlN.com"
                        :value="formData.partialUrls.join('\n')"
                        @change="updateFormData({ partialUrls: $event.target.value.split('\n') })"
                    ></textarea>
                </div>
                <div class="mb-3">
                    <label
                        for="623952F8-3E96-4C81-B293-4361B27DFADC"
                        class="form-label"
                    >Max Degree of Parallelism:</label>
                    <RangeInput
                        id="623952F8-3E96-4C81-B293-4361B27DFADC"
                        :min="1"
                        :max="25"
                        :value="formData.maxDegreeOfParallelism"
                        @update:value="updateFormData({ maxDegreeOfParallelism: $event })"
                    />
                </div>
                <div class="mb-3">
                    <label
                        for="87CD5E2D-3B8B-47EC-8E8E-C1A7FC7EB6EF"
                        class="form-label"
                    >HTTP Request Timeout:</label>
                    <RangeInput
                        id="87CD5E2D-3B8B-47EC-8E8E-C1A7FC7EB6EF"
                        :min="1"
                        :max="30"
                        :value="formData.timeoutSeconds"
                        @update:value="updateFormData({ timeoutSeconds: $event })"
                    />
                </div>
                <div class="mb-3">
                    <label for="0899E904-8491-41FC-9045-7C3B485EFD71" class="form-label">User Agent:</label>
                    <input
                        id="0899E904-8491-41FC-9045-7C3B485EFD71"
                        type="text"
                        class="form-control"
                        :value="formData.userAgent"
                    />
                </div>
                <div class="mb-3">
                    <SpinnerButton :busy="busy" :text="submitButtonText" @click="$emit('submit')" />
                </div>
            </fieldset>
        </form>
    </div>
</template>
<style lang="scss" scoped>
@import "@/scss/_includes.scss";
.panel {
    border: 1px solid $gray-500;
    border-radius: 0.5em;
    padding: 1em;
}
</style>
<script>
import RangeInput from "@/components/RangeInput.vue";
import SpinnerButton from "./SpinnerButton.vue";

export default {
    components: {
        RangeInput,
        SpinnerButton
    },
    props: {
        disabled: Boolean,
        busy: Boolean,
        submitButtonText: String,
        formData: Object
    },
    emits: [
        "update:formData",
        "submit"
    ],
    setup(props, { emit }) {
        const updateFormData = (o) => {
            var formData = Object.assign({}, props.formData, o);
            emit("update:formData", formData);
        };
        return { updateFormData };
    }

}
</script>